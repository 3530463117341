import React from "react";
import I18n from "../public/vendor/i18n";
import { Deployment, foldDeployment_ } from "./deployment";

export const DEFAULT_LANGUAGE = "de";

export function localesForDeployment(deployment: Deployment) {
  return foldDeployment_(deployment, {
    ch: () => [DEFAULT_LANGUAGE, "en", "fr"],
    de: () => [DEFAULT_LANGUAGE, "en"],
  });
}

export function namedLocale(locale: string) {
  switch (locale) {
    case "de":
      return "Deutsch";
    case "en":
      return "English";
    case "fr":
      return "Français";
    default:
      return `[${locale}]`;
  }
}

// -----------------------------------------------------------------------------
// Context

export interface I18nContext {
  defaultLocale: string;
  locale: string;
  t(key: string, o?: unknown): string;
  translations: { [k: string]: unknown };
}

export const emptyI18nContext: I18nContext = {
  defaultLocale: DEFAULT_LANGUAGE,
  locale: DEFAULT_LANGUAGE,
  t: () => "[MISSING TRANSLATION]",
  translations: {},
};

const context = React.createContext<I18nContext>(emptyI18nContext);

export const I18nProvider = ({
  locale,
  children,
  translations,
}: React.PropsWithChildren<Pick<I18nContext, "locale" | "translations">>) => {
  I18n.defaultLocale = DEFAULT_LANGUAGE;
  I18n.locale = locale;
  I18n.translations || (I18n.translations = {});
  I18n.translations[locale] = I18n.extend(I18n.translations[locale] || {}, translations);

  return React.createElement(context.Provider, { value: I18n }, children);
};

export const I18nConsumer = context.Consumer;

export const useI18n = () => React.useContext(context);
