import { RewriteFrames } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";
import getConfig from "next/config";

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.VERSION,

    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename?.replace(`${getConfig().serverRuntimeConfig.rootDir}/.next`, "app:///_next");
          return frame;
        },
      }),

      ...(() => {
        if (process.browser) {
          return [new Integrations.BrowserTracing()];
        } else {
          return [new Sentry.Integrations.Http({ tracing: true })];
        }
      })(),
    ],

    tracesSampleRate: 1.0,
  });
}
